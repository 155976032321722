<template>
	<b-card no-body>
		<b-card-body>
			<b-row>
				<b-col
					cols="12"
					md="4"
					class="mb-2"
				>
					<label>{{ $t('Login Name') }}</label>
					<b-form-input
						v-model="filter.username"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						class="w-100"
            @input="(val) => $emit('update:usernameFilter', val)"
					/>
				</b-col>
				<b-col
					cols="12"
					md="4"
					class="mb-md-0 mb-2"
				>
					<label>{{ $t('Bank') }}</label>
					<v-select
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="bankIdFilter"
						:options="bankOptions"
						class="w-100"
						:reduce="val => val.id"
						label="bank_name"
						@input="(val) => $emit('update:bankIdFilter', val)"
					/>
				</b-col>
        <b-col
					cols="12"
					md="4"
					class="mb-2"
				>
					<label>{{ $t('Bank account') }}</label>
					<b-form-input
						v-model="filter.bankaccount"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						class="w-100"
            @input="(val) => $emit('update:bankaccountFilter', val)"
					/>
				</b-col>
				<b-col
					cols="12"
					md="4"
					class="mb-md-0 mb-0"
				>
					<label>{{ $t('Status') }}</label>
					<v-select
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="statusFilter"
						:options="statusOptions"
						class="w-100"
						:reduce="val => val.value"
						@input="(val) => $emit('update:statusFilter', val)"
					/>
				</b-col>
				<b-col
					cols="12"
					md="4"
					class="mb-0"
				>
					<label>{{ $t('Full Name') }}</label>
					<b-form-input
						v-model="filter.fullname"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						class="w-100"
            @input="(val) => $emit('update:fullnameFilter', val)"
					/>
				</b-col>
				<!-- action -->
				<b-col
					cols="12"
					class="d-flex justify-content-end"
				>
					<b-button
						variant="outline-secondary"
						@click="resetFilter()"
					>
						{{ $t('Reset') }}
					</b-button>
					<b-button
						variant="primary"
						class="ml-2"
						@click="searchFilter()"
					>
						{{ $t('Search') }}
					</b-button>
				</b-col>
			</b-row>
		</b-card-body>
	</b-card>
</template>

<script>
import {
	BFormInput, BCard, BCardHeader, BCardBody, BRow, BCol, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
	components: {
		BRow,
		BCol,
		BCard,
		BFormInput,
		BCardHeader,
		BCardBody,
		BButton,
		vSelect,
	},
	data() {
		return {
			filter: {
				email: null,
				bankId: null,
				bankaccount: null,
				status: null,
				fullname: null,
			},
		}
	},
	props: {
		usernameFilter: {
			type: [String, null],
			default: null,
		},
		bankaccountFilter: {
			type: [String, null],
			default: null,
		},
		statusFilter: {
			type: [String, null],
			default: null,
		},
		bankIdFilter: {
			type: [Number, null],
			default: null,
		},
		statusOptions: {
			type: Array,
			required: true,
		},
		bankOptions: {
			type: Array,
			required: true,
		},
		fullnameFilter: {
			type: [String, null],
			default: null,
		},
    searching: {
      type: [Boolean, null],
      default: false,
    },
	},
	methods: {
		resetFilter() {
			this.filter = {
				username: null,
				bankId: null,
				bankaccount: null,
				status: null,
				fullname: null,
			}
			this.$emit('update:usernameFilter', null)
			this.$emit('update:bankaccountFilter', null)
			this.$emit('update:statusFilter', null)
			this.$emit('update:bankIdFilter', null)
			this.$emit('update:fullnameFilter', null)
		},
		searchFilter() {
			this.$emit('update:searching', !this.searching)
		},
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
